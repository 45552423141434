import { Button } from "@progress/kendo-react-buttons";
import { useMemo, useState } from "react";
import type { ViewLegsForDriverResponse } from "../../../new/api/JobApi";
import { LegStatusType, jobApi, toasted } from "../../../new/helpers";

type DriverLegActionButtonProps = {
	leg: ViewLegsForDriverResponse;
	refetch: () => void;
};
export const DriverLegActionButton = ({
	leg,
	refetch,
}: DriverLegActionButtonProps) => {
	const [loading, setLoading] = useState(false);
	const canChangeToNextStatus = useMemo(() => {
		const status = leg?.status;
		if (!status) return false;
		const nextStatus = (status + 1) as LegStatusType;
		if (!leg.availableStatuses?.includes(nextStatus)) return false;
		return true;
	}, [leg?.status, leg?.availableStatuses]);
	const text = useMemo(() => {
		if (leg?.status === LegStatusType.Planned) return "Accept";
		if (leg?.status === LegStatusType.Accepted) return "Start";
		if (leg?.status === LegStatusType.InStartPosition) return "Collect";
		if (leg?.status === LegStatusType.Underway) return "Deliver";
		if (leg?.status === LegStatusType.InEndPosition) return "Complete";
		return "Change status";
	}, [leg?.status]);
	const handleStatusForward = async () => {
		if (!leg?.status) return;
		const nextStatus = (leg.status + 1) as LegStatusType;
		if (!leg.availableStatuses?.includes(nextStatus)) return;
		setLoading(true);
		await toasted(
			jobApi.leg
				.legChangeStatusCreate({
					legId: leg.id,
					status: nextStatus,
				})
				.then(refetch)
				.finally(() => setLoading(false)),
			"Changing status",
		);
	};
	return (
		<Button
			icon={loading ? "loading" : "check"}
			themeColor="primary"
			disabled={!canChangeToNextStatus || loading}
			onClick={handleStatusForward}
		>
			{text}
		</Button>
	);
};
