import { Button } from "@progress/kendo-react-buttons";
import { useLocation } from "react-router-dom";
import { authAtoms } from "../../new/authAtoms";
import { ForgottenPasswordForm } from "../../new/forms/ForgottenPasswordForm";
import { authenticationApi } from "../../new/helpers";
import { useDialog } from "../../new/helpersReact";
import { DriverBasicPageCard } from "../DriverBasicPageCard";

export const DriverForgotPasswordPage = () => {
	const location = useLocation();
	const email = location.state?.email as string | undefined;
	const [showDialog, dialog] = useDialog(
		<>
			If you are configured in the system, <br />
			an email has been sent with a link to reset your password. <br />
			Please click the link when you get it.
			<br />
			<br />
			<Button onClick={() => authAtoms.logout()}>Go back to login</Button>
		</>,
		"Forgotten Password",
	);
	return (
		<DriverBasicPageCard title="Forgotten Password">
			{dialog}
			<ForgottenPasswordForm
				email={email}
				onSubmit={async (x) => {
					await authenticationApi.authentication
						.authenticationForgotPasswordCreate(x)
						.finally(showDialog);
				}}
				onGoBack={() => authAtoms.logout()}
			/>
		</DriverBasicPageCard>
	);
};
