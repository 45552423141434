import { Redirect, Route, Switch } from "wouter";
import { useNotifyUpdate, useTracker } from "../new/helpers";
import { DriverBUPage } from "./pages/DriverBUPage";
import { DriverForgotPasswordPage } from "./pages/DriverForgotPasswordPage";
import { DriverHomePage } from "./pages/DriverHomePage/DriverHomePage";
import { DriverLoginPage } from "./pages/DriverLoginPage";
import { DriverResetPasswordPage } from "./pages/DriverResetPasswordPage";

export const App = () => {
	useTracker();
	useNotifyUpdate();
	return (
		<Switch>
			<Route path="/" component={DriverHomePage} />
			<Route path="/login" component={DriverLoginPage} />
			<Route path="/business-unit-selection" component={DriverBUPage} />
			<Route path="/forgotten-password" component={DriverForgotPasswordPage} />
			<Route path="/reset-password" component={DriverResetPasswordPage} />
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);
};
