import { Icon } from "@progress/kendo-react-common";
import { useAtomValue } from "jotai";
import type { PropsWithChildren } from "react";
import { authAtoms } from "../../../new/authAtoms";
import { DFlex } from "../../../new/display/DFlex";
import { DGrid } from "../../../new/display/DGrid";
import { useMenuDialog } from "./useMenuDialog";

export const DriverHomeLayout = ({ children }: PropsWithChildren) => {
	const username = useAtomValue(authAtoms.usernameAtom);
	const [toggleMenuDialog, menuDialog] = useMenuDialog();
	return (
		<DGrid rows="auto 1fr" style={{ margin: "0 20px" }}>
			<title>Home Page</title>
			{menuDialog}
			<DFlex spaceBetween vCenter>
				<h1>Work assigned</h1>
				{username && <h3>({username})</h3>}
				<Icon
					name="reorder"
					style={{ zoom: 3, cursor: "pointer" }}
					onClick={toggleMenuDialog}
				/>
			</DFlex>
			<div>{children}</div>
		</DGrid>
	);
};
