import { useSearchParams } from "react-router-dom";
import { authAtoms } from "../../new/authAtoms";
import { ResetPasswordForm } from "../../new/forms/ResetPasswordForm";
import { authenticationApi, toasted } from "../../new/helpers";
import { DriverBasicPageCard } from "../DriverBasicPageCard";

export const DriverResetPasswordPage = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token")?.split(" ").join("+");
	const email = searchParams.get("email");
	if (!token || !email) return "Invalid URL.";
	return (
		<DriverBasicPageCard title="Reset Password">
			<ResetPasswordForm
				onSubmit={async (x) => {
					await toasted(
						authenticationApi.authentication
							.authenticationResetPasswordCreate({
								email: email,
								token: token,
								password: x.password,
							})
							.then(() => authAtoms.logout()),
						"Resetting Password",
					);
				}}
			/>
		</DriverBasicPageCard>
	);
};
