import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import type { ReactNode } from "react";
import { DriverLayout } from "./DriverLayout";

export const DriverBasicPageCard = ({
	title,
	children,
}: { title: string; children: ReactNode }) => {
	return (
		<DriverLayout>
			<Card style={{ minWidth: "min(100%, 400px)" }}>
				<title>{title}</title>
				<CardHeader>{title}</CardHeader>
				<CardBody>{children}</CardBody>
			</Card>
		</DriverLayout>
	);
};
