import { Button } from "@progress/kendo-react-buttons";
import { useAtomValue } from "jotai";
import { navigate } from "wouter/use-browser-location";
import { authAtoms } from "../../../new/authAtoms";
import { DFlex } from "../../../new/display/DFlex";
import { useDialog } from "../../../new/helpersReact";

export const useMenuDialog = () => {
	const auth = useAtomValue(authAtoms.atom);
	const [toggleMenuDialog, menuDialog] = useDialog(
		<DFlex column>
			<Button
				themeColor="primary"
				style={{ width: "100%" }}
				onClick={() => authAtoms.logout()}
			>
				Log out
			</Button>
			<Button
				themeColor="primary"
				style={{ width: "100%" }}
				onClick={() => authAtoms.changeBU()}
				disabled={auth?.user?.businessUnits.length === 1}
			>
				Change business unit
			</Button>
			<Button
				themeColor="primary"
				style={{ width: "100%" }}
				onClick={() => navigate("/forgotten-password")}
			>
				Forgot password
			</Button>
			<Button
				themeColor="secondary"
				style={{ width: "100%" }}
				onClick={() => toggleMenuDialog()}
			>
				Cancel
			</Button>
		</DFlex>,
		"Menu",
	);
	return [toggleMenuDialog, menuDialog] as const;
};
