import { createStore } from "jotai";
import { atomWithStorage, selectAtom } from "jotai/utils";
import { navigate } from "wouter/use-browser-location";
import type {
	AuthenticationResponse,
	UserLoginRequest,
	ViewBusinessUnitResponse,
} from "./api/AuthenticationApi";
import { authenticationApi } from "./helpers";

type Auth = {
	businessUnit?: ViewBusinessUnitResponse;
	user?: AuthenticationResponse;
};

export const store = createStore();
const authAtom = atomWithStorage<Auth | null>(
	"auth",
	JSON.parse(localStorage.getItem("auth") || "null"),
);
export const authAtoms = {
	atom: authAtom,
	usernameAtom: selectAtom(authAtom, (x) =>
		`${x?.user?.userFirstName ?? ""} ${x?.user?.userLastName ?? ""}`.trim(),
	),
	login: async (params: UserLoginRequest) => {
		const res =
			await authenticationApi.authentication.authenticationCreate(params);
		store.set(authAtom, { user: res.data });
		return res;
	},
	logout: () => {
		store.set(authAtom, null);
		navigate("/login");
	},
	changeBU: () => {
		store.set(authAtom, {
			...store.get(authAtom),
			businessUnit: undefined,
		});
		navigate("/business-unit-selection");
	},
	setBU: async (id: string) => {
		const res = await authenticationApi.businessUnit.businessUnitDetail(id);
		store.set(authAtom, {
			...store.get(authAtom),
			businessUnit: res.data,
		});
		return res;
	},
} as const;
