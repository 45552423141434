import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { type InferType, object, string } from "yup";
import { IPassword, IText } from "./GenericForm";

const LoginSchema = object({
	username: string().email().required().label("Email"),
	password: string().required().label("Password"),
});

type Login = InferType<typeof LoginSchema>;

type LoginFormProps = {
	onSubmit: (data: Login) => Promise<unknown>;
	onForgottenPassword: (email: string) => unknown;
};
export const LoginForm = ({
	onSubmit,
	onForgottenPassword,
}: LoginFormProps) => {
	const [loading, setLoading] = useState(false);
	const { handleSubmit, control, getValues } = useForm<Login>({
		resolver: yupResolver(LoginSchema),
		defaultValues: { username: "", password: "" },
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit(async (x) => {
				setLoading(true);
				await onSubmit(x).finally(() => setLoading(false));
			})}
		>
			<IText c={control} s={LoginSchema} n="username" />
			<IPassword c={control} s={LoginSchema} n="password" />
			<p style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
				<Button
					type="button"
					onClick={() => onForgottenPassword(getValues().username)}
				>
					Forgot password
				</Button>
				<Button
					type="submit"
					themeColor="primary"
					icon={loading ? "loading" : undefined}
					disabled={loading}
				>
					Log in
				</Button>
			</p>
		</form>
	);
};
