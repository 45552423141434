import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { type InferType, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import { IText } from "./GenericForm";

const ForgottenPasswordSchema = object({
	email: string().email().required().label("Email"),
});

type ForgottenPassword = InferType<typeof ForgottenPasswordSchema>;

type ForgottenPasswordFormProps = {
	email?: string;
	onSubmit: (data: ForgottenPassword) => Promise<unknown>;
	onGoBack: () => unknown;
};
export const ForgottenPasswordForm = ({
	email = "",
	onSubmit,
	onGoBack,
}: ForgottenPasswordFormProps) => {
	const [loading, setLoading] = useState(false);
	const { handleSubmit, control } = useForm<ForgottenPassword>({
		resolver: yupResolver(ForgottenPasswordSchema),
		defaultValues: { email },
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit(async (x) => {
				setLoading(true);
				await onSubmit(x).finally(() => setLoading(false));
			})}
		>
			<IText c={control} s={ForgottenPasswordSchema} n="email" />
			<DFlex flexEnd>
				<Button type="button" onClick={onGoBack}>
					Go back to login
				</Button>
				<Button
					type="submit"
					themeColor="primary"
					icon={loading ? "loading" : undefined}
					disabled={loading}
				>
					Email me a reset link
				</Button>
			</DFlex>
		</form>
	);
};
