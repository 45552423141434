import { navigate } from "wouter/use-browser-location";
import { authAtoms } from "../../new/authAtoms";
import { LoginForm } from "../../new/forms/LoginForm";
import { toasted } from "../../new/helpers";
import { DriverBasicPageCard } from "../DriverBasicPageCard";

export const DriverLoginPage = () => (
	<DriverBasicPageCard title="Login">
		<LoginForm
			onSubmit={async (x) => {
				await toasted(authAtoms.login(x), "Authenticating");
				authAtoms.changeBU();
			}}
			onForgottenPassword={(email) =>
				navigate("/forgotten-password", { state: { email } })
			}
		/>
	</DriverBasicPageCard>
);
