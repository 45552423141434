import type { CSSProperties, ReactNode } from "react";

type GridProps = {
	children: ReactNode;
	gap?: string;
	noGap?: boolean;
	rows?: string;
	columns?: string;
	style?: CSSProperties;
};
export const DGrid = (props: GridProps) => (
	<div
		style={{
			display: "grid",
			gap: props.noGap ? undefined : (props.gap ?? "16px"),
			gridTemplateRows: props.rows,
			gridTemplateColumns: props.columns,
			...props.style,
		}}
	>
		{props.children}
	</div>
);
