import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { navigate } from "wouter/use-browser-location";
import { authAtoms } from "../../new/authAtoms";
import { DFlex } from "../../new/display/DFlex";
import { toasted } from "../../new/helpers";
import { DriverBasicPageCard } from "../DriverBasicPageCard";

export const DriverBUPage = () => {
	const auth = useAtomValue(authAtoms.atom);
	const setBU = useCallback(
		(buId: string) =>
			toasted(
				authAtoms.setBU(buId).then(() => navigate("/")),
				"Setting Business Unit",
			),
		[],
	);
	useEffect(() => {
		if (auth?.user?.businessUnits.length === 0) {
			toast.error("No business units found for user");
			return authAtoms.logout();
		}
		if (auth?.user?.businessUnits.length !== 1) return;
		if (!auth.user.businessUnits[0]?.id) return;
		setBU(auth.user.businessUnits[0].id);
	}, [
		setBU,
		auth?.user?.businessUnits.length,
		auth?.user?.businessUnits[0]?.id,
	]);
	return (
		<DriverBasicPageCard title="Select Business Unit">
			<DFlex column>
				<DFlex column>
					{auth?.user?.businessUnits.map((bu) => (
						<Card key={bu.id}>
							<DFlex spaceBetween vCenter>
								<div>{bu.name}</div>
								<Button onClick={() => setBU(bu.id)} themeColor="primary">
									Select
								</Button>
							</DFlex>
						</Card>
					))}
				</DFlex>
				<DFlex flexEnd>
					<Button
						themeColor="error"
						type="button"
						onClick={() => authAtoms.logout()}
					>
						Log out
					</Button>
				</DFlex>
			</DFlex>
		</DriverBasicPageCard>
	);
};
