import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { checkIcon, trashIcon } from "@progress/kendo-svg-icons";
import html2canvas from "html2canvas";
import { useRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { toast } from "react-toastify";
import { useInterval } from "react-use";
import { DFlex } from "../../../new/display/DFlex";
import { useDialog } from "../../../new/helpersReact";

const Now = () => {
	const [now, setNow] = useState(new Date());
	useInterval(() => setNow(new Date()), 1000);
	return now.toUTCString();
};
function dataURLtoBlob(dataurl: string) {
	const arr = dataurl.split(",");
	const arr0 = arr[0];
	const arr1 = arr[1];
	if (!arr0 || !arr1) throw new Error("Invalid data URL");
	const mime = arr0.match(/:(.*?);/)?.[1];
	const bstr = atob(arr1);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}
type PODProps = {
	onSubmit: (pod: Blob) => unknown;
	onCancel?: () => void;
};
const POD = ({ onSubmit, onCancel }: PODProps) => {
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);
	const signaturePadRef = useRef<SignaturePad>(null);
	const printRef = useRef<HTMLDivElement>(null);
	const handleSubmit = async () => {
		setLoading(true);
		try {
			const isEmpty = signaturePadRef.current?.isEmpty() ?? true;
			const element = printRef.current;
			if (name === "") return toast.error("Name is required");
			if (isEmpty) return toast.error("Signature is required");
			if (!element) return toast.error("The element is not ready");
			const canvas = await html2canvas(element);
			const data = canvas.toDataURL("image/png");
			await onSubmit(dataURLtoBlob(data));
		} finally {
			setLoading(false);
		}
	};
	const handleClear = () => {
		signaturePadRef.current?.clear();
		setName("");
	};
	return (
		<DFlex column>
			<div ref={printRef}>
				<DFlex center>
					<h1 style={{ marginTop: 0 }}>Proof of Delivery</h1>
				</DFlex>
				<DFlex column>
					<div>
						<i>Date:</i>
						<div>
							<b>
								<Now />
							</b>
						</div>
					</div>
					<div>
						<i>Name:</i>
						<div>
							<Input
								placeholder="Name..."
								value={name}
								onChange={(e) => setName(e.value)}
							/>
						</div>
					</div>
					<div>
						<i>Signature:</i>
						<div
							style={{
								width: "100%",
								border: "1px solid #9d9dab",
								borderRadius: "4px",
							}}
						>
							<SignaturePad ref={signaturePadRef} />
						</div>
					</div>
				</DFlex>
			</div>
			<DFlex spaceBetween>
				<Button svgIcon={checkIcon} themeColor="error" onClick={onCancel}>
					Cancel
				</Button>
				<Button svgIcon={trashIcon} onClick={handleClear}>
					Clear
				</Button>
				<Button
					disabled={!name || loading}
					icon={loading ? "loading" : "save"}
					themeColor="primary"
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</DFlex>
		</DFlex>
	);
};

export const usePODDialog = (onSubmit: (pod: Blob) => unknown) => {
	const [togglePODDialog, podDialog] = useDialog(
		<POD
			onSubmit={async (pod) => {
				await onSubmit(pod);
				togglePODDialog();
			}}
			onCancel={() => togglePODDialog()}
		/>,
		"Proof of Delivery",
	);
	return [togglePODDialog, podDialog] as const;
};
